import { createTheme } from '@material-ui/core';

import BebasNeueRegularWoff2 from './fonts/BebasNeue/BebasNeue-Regular.woff2';
import BebasNeueRegularWoff from './fonts/BebasNeue/BebasNeue-Regular.woff';
import BebasNeueRegularTrueType from './fonts/BebasNeue/BebasNeue-Regular.ttf';
import BebasNeueBoldWoff2 from './fonts/BebasNeue/BebasNeue-Bold.woff2';
import BebasNeueBoldWoff from './fonts/BebasNeue/BebasNeue-Bold.woff';
import BebasNeueBoldTrueType from './fonts/BebasNeue/BebasNeue-Bold.ttf';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    sidebarWidth: number;
    // sidebarMobileHeight: number;
    whiteBasic: string;
    trueblackBasic: string;
    brand: string;
    grey: string;
    greyMain: string;
    greyLight: string;
    greyInactive: string;
    greenMain: string;
    greenLight: string;
    blackGrey: string;
    blueGrey: string;
    red: string;
    redLight: string;
    violetBasic: string;
    greyDisabled: string;
    bottomNavigationHeight: number;
    // footerHeight: number;
    // mobileTopBarHeight: number;
    // mobileFooterHeight: number;
    // sidebarMobilePadding: number;
    // participantBorderWidth: number;
    rightDrawerWidth: number;
    // galleryViewBackgroundColor: string;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    components?: object;
    sidebarWidth?: number;
    // sidebarMobileHeight?: number;
    whiteBasic: string;
    trueblackBasic: string;
    brand: string;
    grey: string;
    greyMain: string;
    greyLight: string;
    greyInactive: string;
    greenMain: string;
    greenLight: string;
    blackGrey: string;
    blueGrey: string;
    red: string;
    redLight: string;
    violetBasic: string;
    greyDisabled: string;
    bottomNavigationHeight: number;
    // footerHeight: number;
    // mobileTopBarHeight: number;
    // mobileFooterHeight: number;
    // sidebarMobilePadding: number;
    // participantBorderWidth: number;
    rightDrawerWidth?: number;
    // galleryViewBackgroundColor: string;
  }
}

const BebasNeueRegular = {
  'font-family': 'BebasNeue',
  'font-style': 'normal',
  // fontDisplay: "swap",
  'font-weight': 300,
  src: `
    local('BebasNeue'),
    local('BebasNeue-Regular'),
    url(${BebasNeueRegularTrueType}) format("truetype"),
    url(${BebasNeueRegularWoff2}) format("woff2"),
    url(${BebasNeueRegularWoff}) format("woff")
  `,
};

// MozFontFeatureSettings?: FontFaceFontFeatureSettingsProperty;
// ascentOverride?: FontFaceAscentOverrideProperty;
// descentOverride?: FontFaceDescentOverrideProperty;
// fontDisplay?: FontFaceFontDisplayProperty;
// fontFamily?: string;
// fontFeatureSettings?: FontFaceFontFeatureSettingsProperty;
// fontStretch?: FontFaceFontStretchProperty;
// fontStyle?: FontFaceFontStyleProperty;
// fontVariant?: FontFaceFontVariantProperty;
// fontVariationSettings?: FontFaceFontVariationSettingsProperty;
// fontWeight?: FontFaceFontWeightProperty;
// lineGapOverride?: FontFaceLineGapOverrideProperty;
// sizeAdjust?: string;
// src?: string;
// unicodeRange?: string;

const BebasNeueBold = {
  'font-family': 'BebasNeue',
  'font-style': 'normal',
  // fontDisplay: "swap",
  'font-weight': 700,
  src: `
    local('BebasNeue'),
    local('BebasNeue-Bold'),
    url(${BebasNeueBoldTrueType}) format("truetype"),
    url(${BebasNeueBoldWoff2}) format("woff2"),
    url(${BebasNeueBoldWoff}) format("woff")
  `,
};

// @font-face{
//     font-family: BebasNeue;
//     src: url("@{webpackFontPath}BebasNeue/BebasNeue-Bold.ttf") format("truetype"),
//          url("@{webpackFontPath}BebasNeue/BebasNeue-Bold.woff2") format("woff2"),
//          url("@{webpackFontPath}BebasNeue/BebasNeue-Bold.woff") format("woff");
//     font-weight:700;
//     font-style:normal;
// }

const defaultTheme = createTheme();

export default createTheme({
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          // minHeight: '-webkit-fill-available',
        },
        ':root': {
          // '--safe-area-inset-top': 'env(safe-area-inset-top)',
          // '--safe-area-inset-bottom': 'env(safe-area-inset-bottom)',
        },
        'html, body, #root': {
          'background-color': 'rgb(248, 248, 248)', // #F8F8F8
        },
        body: {
          'font-size': '16px',
          width: '100%',
        },
        '@font-face': [BebasNeueRegular, BebasNeueBold],
        // // You should target [class*="MuiButton-root"] instead if you nest themes.
        // '.MuiButton-root': {
        //   'font-size': '1rempx',
        // },
      },
    },
    MuiCheckbox: {
      root: {
        // 'border-radius': '8px',
        // '&$root$root:hover': {
        //   'background-color': 'transparent'
        // },
        '&$checked': {
          // color: "rgba(0, 0, 0, 0.54)",
          '& .MuiIconButton-label': {
            'background-color': 'rgb(233, 78, 50)', // #E94E32
          },
        },
        '& .MuiIconButton-label': {
          'box-shadow': '0px 4px 20px rgba(0, 0, 0, 0.1)',
          'border-radius': '16px',
        },
      },
    },
    MuiButton: {
      root: {
        'border-radius': '8px',
        padding: '19px 14px',
        color: 'rgb(255, 255, 255)', // defaultTheme.,
        'font-size': '18px',
        transition: defaultTheme.transitions.create(['background-color', /*'box-shadow',*/ 'border', 'color'], {
          duration: defaultTheme.transitions.duration.short,
        }),
        '&:hover': {
          'background-color': 'rgba(0, 0, 0, 0.4)',
        },
      },
      label: {
        color: 'rgb(255, 255, 255)', // #FFFFFF
      },
      text: {
        padding: '19px 14px',
      },
      contained: {
        /* .tbk-grey */
        'background-color': 'rgb(107, 113, 120)', // #6B7178
        '& $disabled': {
          // .tbk-grey-inactive
          'background-color': 'rgb(224, 224, 224)', // #e0e0e0 //'rgb(203, 207, 213)',
        },
        '& .MuiButton-startIcon': {
          // needed
          'margin-right': '4px',
        },
      },
      containedPrimary: {
        'background-color': 'rgb(233, 78, 50)', // #E94E32
        '@media(hover: none)': {
          '&.MuiButton-contained:hover': {
            '&:not($disabled)': {
              'background-color': 'rgb(241, 100, 74)', // #F1644A
            },
          },
        },
        '&:active': {},
        '& $disabled': {
          // .tbk-grey-inactive
          'background-color': 'rgb(224, 224, 224)', // #e0e0e0 // rgba(0, 0, 0, 0.12);
        },
        '&:hover $disabled': {
          // .tbk-grey-inactive
          'background-color': 'rgb(224, 224, 224)', // #e0e0e0 // rgba(0, 0, 0, 0.12);
        },
        startIcon: {
          'margin-right': '4px',
        },
      },
      containedSecondary: {
        'background-color': 'rgb(255, 255, 255)', // #FFFFFF
        color: 'rgb(48, 55, 64)', // #303740
        '@media(hover: none)': {
          '&.MuiButton-contained:hover': {
            '&:not($disabled)': {
              'background-color': 'rgb(255, 255, 255)', // #FFFFFF
            },
          },
        },
        '&:active': {},
        '& $disabled': {
          // .tbk-grey-inactive
          'background-color': 'rgb(224, 224, 224)', // #e0e0e0 // rgba(0, 0, 0, 0.12);
        },
        '&:hover $disabled': {
          // .tbk-grey-inactive
          'background-color': 'rgb(224, 224, 224)', // #e0e0e0 // rgba(0, 0, 0, 0.12);
        },
      },
      outlinedPrimary: {
        padding: '17px 14px',
        color: 'rgb(233, 78, 50)', // #E94E32
        'background-color': 'transparent',
        border: '2px solid rgb(233, 78, 50)', // #E94E32 //'2px solid #027AC5',
        label: {
          color: 'rgb(233, 78, 50)', // #E94E32
        },
        '&:not($disabled) .MuiButton-label': {
          color: 'rgb(233, 78, 50)', // #E94E32
        },
        '&$disabled': {
          '& .MuiButton-label': {
            color: 'rgb(203, 207, 213)', // #CBCFD5
          },
        },
        '@media(hover: none)': {
          '&.MuiButton-outlined:hover': {
            '&:not($disabled)': {
              'background-color': 'rgb(241, 100, 74)', // #F1644A
              border: '2px solid rgb(241, 100, 74)', // #F1644A // '2px solid rgb(1, 85, 137)',
              '& .MuiButton-label': {
                color: 'rgb(255, 255, 255)', // #FFFFFF
              },
            },
          },
        },
      },
      outlinedSecondary: {
        padding: '17px 14px',
        color: 'rgb(107, 113, 120)', // #6B7178
        'background-color': 'transparent',
        border: '2px solid rgb(107, 113, 120)', // #6B7178 //'2px solid #027AC5',
        label: {
          color: 'rgb(107, 113, 120)', // #6B7178
        },
        '& .MuiButton-label': {
          color: 'rgb(107, 113, 120)', // #6B7178
        },
        '&:hover': {
          'background-color': 'rgb(241, 100, 74)', // #F1644A
          border: '2px solid rgb(241, 100, 74)', // #F1644A // '2px solid rgb(1, 85, 137)',
          '& .MuiButton-label': {
            color: 'rgb(107, 113, 120)', // white, // #FFFFFF
          },
        },
      },
      startIcon: {
        'margin-right': '8px',
      },
    },
    MuiTypography: {
      body1: {
        color: 'rgb(48, 55, 64)', // #303740 //'rgb(40, 42, 43)',
      },
    },
    MuiInputBase: {
      root: {
        'font-size': '0.9rem',
      },
      input: {
        'font-size': '16px',
        'line-height': '26px',
        border: '1px solid rgb(205, 207, 213)', // #CDCFD5
        padding: '10px 16px',
        height: '26px',
        'background-color': 'rgb(255, 255, 255)', // #FFFFFF
        'border-radius': '8px',
      },
    },
    MuiSelect: {
      root: {
        padding: '0.85em',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '24px', // '16px',
        width: '100%',
        'justify-content': 'stretch',
      },
    },
    MuiTextField: {
      root: {
        color: 'rgb(40, 42, 43)',
      },
    },
    MuiInputLabel: {
      root: {
        color: 'rgb(107, 113, 120)', // #6B7178 // 'rgb(40, 42, 43)',
        'margin-left': '1rem',
        'margin-bottom': '0.5rem',
        // 'font-size': '1px'.1rem',
        'font-weight': 400, //500,
        'font-size': '12px',
        'line-height': '18px',
        'text-align': 'left',
      },
    },
    MuiOutlinedInput: {
      // Login Form
      root: {
        'border-radius': '8px',
      },
      input: {
        'font-weight': 400,
        'font-size': '16px',
        'line-height': '26px',
        padding: '19px 16px',
        'padding-top': '14px',
        'padding-bottom': '14px',
        border: '1px solid rgb(203, 207, 213)', // #CBCFD5
        'border-color': 'transparent',
        'background-color': 'rgb(255, 255, 255)', // #FFFFFF
        'border-radius': '8px',
        'text-overflow': 'ellipsis',
      },
      notchedOutline: {
        'border-color': 'rgb(136, 140, 142)',
      },
    },
  },

  typography: {
    fontFamily: [
      'BebasNeue',
      'AvenirNextCondensed-Bold',
      'Futura-CondensedExtraBold',
      'HelveticaNeue-CondensedBold',
      'Arial Narrow',
      'Helvetica Neue',
      'sans-serif-condensed',
      'sans-serif',
    ].join(','),

    /* .tbk-text-main */
    body1: {
      'font-family': "'Poppins', Arial, sans-serif",
      'font-size': '16px',
      'font-weight': 400,
      'line-height': '26px',
    },
    /* .tbk-text-title-bold */
    body2: {
      'font-family': "'Poppins', Arial, sans-serif",
      'font-size': '24px',
      'font-weight': 600,
      'line-height': '36px',
    },
    /* .tbk-text-small */
    subtitle1: {
      'font-size': '12px',
      'font-family': "'Poppins', Arial, sans-serif",
      'line-height': '18px',
      'font-weight': 400,
    },
    /* .tbk-text-small-bold */
    caption: {
      'font-size': '12px',
      'font-family': "'Poppins', Arial, sans-serif",
      'line-height': '14px',
      'font-weight': 700,
    },
    /* H1 Headline */
    h1: {
      // 'font-family': 'BebasNeue',
      'font-weight': 700,
      'font-size': '60px',
      'line-height': '60px',
    },
    /* .tbk-text-medium-headline */
    h2: {
      'font-size': '40px',
      // 'font-family': "'Poppins', Arial, sans-serif",
      'line-height': '40px',
      'font-weight': 700,
    },
    /* .tbk-text-h3-subtitle */
    h3: {
      'font-size': '18px',
      'font-family': "'Poppins', Arial, sans-serif",
      'line-height': '26px',
      'font-weight': 600,
    },
    /* Navigation Narrow */
    h4: {
      'font-size': '26px',
      // 'font-family': "'Poppins', Arial, sans-serif",
      'line-height': '26px',
      'font-weight': 700,
    },
    /* .tbk-text-epic-medium */
    h5: {
      'font-size': '22px',
      'font-family': "'Poppins', Arial, sans-serif",
      'line-height': '33px',
      'font-weight': 400,
    },
    /* .tbk-text-cta */
    button: {
      'font-family': 'Poppins',
      'font-weight': 600,
      'font-size': '18px',
      'line-height': '26px',
    },
    /* .tbk-text-main-linked */
    subtitle2: {
      'font-family': 'Poppins',
      'font-weight': 400,
      'font-size': '16px',
      'line-height': '26px',
    },
  },
  palette: {
    primary: {
      main: 'rgb(48, 55, 64)', // #303740 // '#027AC5',
      light: 'rgb(248, 248, 248)', // #F8F8F8
      dark: 'rgb(107, 113, 120)', // #6B7178
      // dark: will be calculated from palette.secondary.main,
      contrastText: 'rgb(255, 255, 255)', // #FFFFFF
    },
    secondary: {
      main: 'rgb(255, 255, 255)', // '#027AC5', // #FFFFFF
      contrastText: 'rgb(107, 113, 120)', // #6B7178
    },
    action: {
      active: 'rgb(233, 78, 50)', // #E94E32 // lightBlue[200],
      // activeOpacity: 1,
      hover: 'rgb(248, 248, 248)', // #F8F8F8 // lightBlue[100],
      // hoverOpacity: 0.7,
      // focus: lightBlue[600],
      // focusOpacity: 1,
      // selected: lightBlue[300],
      // selectedOpacity: 1
    },
    error: {
      main: 'rgb(255, 117, 87)', // #303740 // '#027AC5',
      // light: 'rgb(248, 248, 248)', // #F8F8F8
      // dark: 'rgb(107, 113, 120)', // #6B7178
      // dark: will be calculated from palette.secondary.main,
      // contrastText: 'rgb(255, 255, 255)', // #FFFFFF
    },
  },
  brand: 'rgb(226, 37, 37)', // #E22525,
  grey: 'rgb(107, 113, 120)', // #6B7178
  greyMain: 'rgb(48, 55, 64)', // #303740
  greyLight: 'rgb(248, 248, 248)', // #F8F8F8
  greyInactive: 'rgb(203, 207, 213)', // #CBCFD5
  red: 'rgb(233, 78, 50)', // #E94E32
  redLight: 'rgb(241, 100, 74)', // #F1644A
  whiteBasic: 'rgb(255, 255, 255)', // #FFFFFF
  trueblackBasic: 'rgb(0, 0, 0)',
  greenMain: 'rgb(59, 198, 147)', // #3BC693
  greenLight: 'rgb(97 210 168)', // #61D2A8
  blackGrey: 'rgb(17, 20, 24)', // #111418
  blueGrey: 'rgb(114, 135, 154)', //#72879A
  violetBasic: 'rgb(173, 96, 208)', // #AD60D0
  greyDisabled: 'rgb(224, 224, 224)', // #e0e0e0
  bottomNavigationHeight: 82,
  // footerHeight: 72,
  // mobileFooterHeight: 56,
  // sidebarWidth: 300,
  // sidebarMobileHeight: 90,
  // sidebarMobilePadding: 8,
  // participantBorderWidth: 2,
  // mobileTopBarHeight: 52,
  // rightDrawerWidth: 320,
  // galleryViewBackgroundColor: '#121C2D',
});
