import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Client } from '@twilio/conversations';
import { Conversation } from '@twilio/conversations/';
// import { Message } from '@twilio/conversations/';
import { Message, Chat, Channel, User, Membership } from '@pubnub/chat';
import { useAppState } from '../../state';
import { isIosDevice } from '../../utils';
// import Snackbar from '../../Snackbar/Snackbar';
//import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

// include the PubNub Chat SDK package in your code before initializing the client. Underneath, that also installs the PubNub JavaScript SDK "pubnub" package as a dependency.

export interface UnreadMessagesOnChannel {
  channel: Channel;
  count: number;
}

export interface LatestMessagesOnChannel {
  channelId: string;
  message: Message | null;
}

export enum ChatEventTypes {
  LEAVE = 0, //  Notify other members of a group that you are leaving that group
  JOINED = 3, //  Notify others in a group that you have joined as a new member (for public channels)
}

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  //connect: (token: string) => void;
  chatClient: any;
  currentUser: User | null;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
  // channel: Channel | null,
  activeChannel: Channel | null;
  setActiveChannel: any;
  activeChannelPinnedMessage: any;
  setActiveChannelPinnedMessage: any;
  setUnreadMessages: any;
  privateGroups: Channel[] | undefined;
  privateGroupsUsers: User[][];
  directChats: Channel[] | undefined;
  publicChannels: Channel[] | undefined;
  publicChannelsUsers: User[][];
  directChatsUsers: User[][];
  publicChannelsMemberships: any;
  privateGroupsMemberships: any;
  directChatsMemberships: any;
  unreadMessages: UnreadMessagesOnChannel[];
  latestMessages: LatestMessagesOnChannel[];
  updateChannelMembershipsForPublic: (chat: Chat, channelId?: any) => void;
  updateChannelMembershipsForDirects: (chat: Chat, channelId?: any) => void;
  updateChannelMembershipsForGroups: (chat: Chat, channelId?: any) => void;
};

// interface Channel {
//   custom?: string;
//   description?: string;
//   eTag?: string;
//   id?: string;
//   name?: string;
//   status?: string;
//   type?: string;
//   updated?: string;

//     // /**
//     //  * Any custom attributes to attach to the conversation.
//     //  */
//     // attributes?: JSONValue;
//     // /**
//     //  * A non-unique display name of the conversation.
//     //  */
//     // friendlyName?: string;
//     // /**
//     //  * A unique identifier of the conversation.
//     //  */
//     // uniqueName?: string;
// }

export const ChatContext = createContext<ChatContextType>(null!);

//export const ChatProvider = () => {
export const ChatProvider: React.FC = ({ children }) => {
  const { user } = useAppState();

  // const { room, onError } = useVideoContext();
  const isChatWindowOpenRef = useRef(false);
  //const [userId, setUserId] = useState<String | null | undefined>(user?.id);
  const [chatClient, setChat] = useState<any>(); //useState<Client>();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loadMessage, setLoadMessage] = useState('Chat is initializing...');
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [channels, setChannels] = useState<any[]>([]);
  const [activeChannel, setActiveChannel] = useState<any>(null);
  const [activeChannelPinnedMessage, setActiveChannelPinnedMessage] = useState<Message | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);

  const [name, setName] = useState('');
  const [profileUrl, setProfileUrl] = useState('');
  const [typingData, setTypingData] = useState<string[]>([]);
  const [refreshMembersTimeoutId, setRefreshMembersTimeoutId] = useState<ReturnType<typeof setTimeout>>();
  const [initOnce, setInitOnce] = useState(0);

  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  const [directChats, setDirectChats] = useState<Channel[]>();
  const [publicChannelsMemberships, setPublicChannelsMemberships] = useState<Membership[]>();
  const [privateGroupsMemberships, setPrivateGroupsMemberships] = useState<Membership[]>();
  const [directChatsMemberships, setDirectChatsMemberships] = useState<Membership[]>();
  const [privateGroupsUsers, setPrivateGroupsUsers] = useState<User[][]>([]);
  const [privateGroups, setPrivateGroups] = useState<Channel[]>();
  const [directChatsUsers, setDirectChatsUsers] = useState<User[][]>([]);
  const [publicChannels, setPublicChannels] = useState<Channel[]>();
  const [publicChannelsUsers, setPublicChannelsUsers] = useState<User[][]>([]);
  const [unreadMessages, setUnreadMessages] = useState<UnreadMessagesOnChannel[]>([]);
  const [latestMessages, setLatestMessages] = useState<LatestMessagesOnChannel[]>([]);

  /* Bootstrap the application if it is run in an empty keyset */
  async function keysetInit(chat: Chat) {
    if (!chat) return;
    try {
      await chat?.createPublicConversation({
        channelId: 'public-general',
        channelData: {
          name: 'General Chat',
          description: 'Public group for general conversation',
          custom: {
            profileUrl: '/group/globe1.svg',
          },
        },
      });
    } catch (e) {}

    try {
      await chat?.createPublicConversation({
        channelId: 'public-work',
        channelData: {
          name: 'Work Chat',
          description: 'Public group for conversation about work',
          custom: {
            profileUrl: '/group/globe2.svg',
          },
        },
      });
    } catch (e) {}
  }

  /*  Initialize or Update all the state arrays related to public groups */
  async function updateChannelMembershipsForPublic(chat: Chat) {
    if (!chat) {
      return;
    }
    //  During development there was an issue filtering on getMemberships on the server, which has since been fixed, so this code could be made more efficient
    chat.currentUser.getMemberships({ filter: "channel.type == 'direct'" }).then(async membershipResponse => {
      const currentMemberOfThesePublicChannels = membershipResponse.memberships.map(m => m.channel);

      setPublicChannels(currentMemberOfThesePublicChannels);
      const publicChannelMemberships = membershipResponse.memberships;
      setPublicChannelsMemberships(publicChannelMemberships);

      //  Get the users for every public group I am a member of
      let tempPublicUsers: User[][] = [];
      for (var indexGroup = 0; indexGroup < currentMemberOfThesePublicChannels.length; indexGroup++) {
        var tempIndex = indexGroup;
        const response = await currentMemberOfThesePublicChannels[indexGroup].getMembers({
          sort: { updated: 'desc' },
          limit: 40,
        });
        if (response.members) {
          //  response contains the most recent 40 members
          const channelUsers = response.members.map((membership, index) => {
            return membership.user;
          });
          tempPublicUsers[tempIndex] = channelUsers;
        }
      }
      setPublicChannelsUsers(tempPublicUsers);
    });
  }

  /* Initialize or Update all the state arrays related to private groups */
  async function updateChannelMembershipsForGroups(chat: Chat, desiredChannelId = '') {
    if (!chat) return;
    chat.currentUser
      .getMemberships({
        filter: "channel.type == 'group'",
        sort: { updated: 'desc' },
      })
      .then(async membershipResponse => {
        const currentMemberOfTheseGroupChannels = membershipResponse.memberships.map(m => m.channel);
        //  Look for the desired channel ID
        for (var i = 0; i < currentMemberOfTheseGroupChannels.length; i++) {
          if (currentMemberOfTheseGroupChannels[i].id === desiredChannelId) {
            //  We have found the channel we want to focus
            setActiveChannel(currentMemberOfTheseGroupChannels[i]);
          }
        }

        setPrivateGroups(currentMemberOfTheseGroupChannels);
        const groupChannelMemberships = membershipResponse.memberships;
        setPrivateGroupsMemberships(groupChannelMemberships);

        //  Get the users for every private group I am a member of
        let tempGroupUsers: User[][] = [];
        for (var indexGroup = 0; indexGroup < currentMemberOfTheseGroupChannels.length; indexGroup++) {
          //currentMemberOfTheseGroupChannels.forEach((channel, index) => {
          var tempIndex = indexGroup;
          const response = await currentMemberOfTheseGroupChannels[indexGroup].getMembers({
            sort: { updated: 'desc' },
            limit: 100,
          });
          if (response.members) {
            const channelUsers = response.members.map((membership, index) => {
              return membership.user;
            });
            tempGroupUsers[tempIndex] = channelUsers;
          }
        }
        setPrivateGroupsUsers(tempGroupUsers);
      });
  }

  /* Initialize or Update all the state arrays related to Direct message pairs */
  async function updateChannelMembershipsForDirects(chat: any, desiredChannelId = '') {
    if (!chat) return;
    chat.currentUser
      .getMemberships({
        filter: "channel.type == 'direct'",
        sort: { updated: 'desc' },
      })
      .then(async (membershipResponse: any) => {
        const currentMemberOfTheseDirectChannels = membershipResponse.memberships.map((m: any) => m.channel);
        //  Look for the desired channel ID
        for (var i = 0; i < currentMemberOfTheseDirectChannels.length; i++) {
          if (currentMemberOfTheseDirectChannels[i].id === desiredChannelId) {
            //  We have found the channel we want to focus
            const channelToFocus = currentMemberOfTheseDirectChannels[i];
            setActiveChannel(channelToFocus);
            channelToFocus?.registerForPush();
          }
        }
        setDirectChats(currentMemberOfTheseDirectChannels);
        const directChannelMemberships = membershipResponse.memberships;
        setDirectChatsMemberships(directChannelMemberships);

        //  Get the users for every direct message pair I am a member of
        let tempDirectUsers: User[][] = [];
        for (var indexDirects = 0; indexDirects < currentMemberOfTheseDirectChannels.length; indexDirects++) {
          var tempIndex = indexDirects;
          const response = await currentMemberOfTheseDirectChannels[indexDirects].getMembers({
            sort: { updated: 'desc' },
            limit: 100,
          });

          if (response.members) {
            //  response contains the most recent 100 members
            const channelUsers = response.members.map((membership: Membership, index: any) => {
              return membership.user;
            });
            tempDirectUsers[tempIndex] = channelUsers;
          }
        }
        setDirectChatsUsers(tempDirectUsers);
      });
  }

  // const connect = useCallback(
  //   (token: string) => {
  //     const client = new Client(token);

  //     const handleClientInitialized = (state: string) => {
  //       if (state === 'initialized') {
  //         // @ts-ignore
  //         window.chatClient = client;
  //         setChatClient(client);
  //       } else if (state === 'failed') {
  //         onError(new Error("There was a problem connecting to Twilio's conversation service."));
  //       }
  //     };

  //     client.on('stateChanged', handleClientInitialized);

  //     return () => {
  //       client.off('stateChanged', handleClientInitialized);
  //     };
  //   },
  //   [onError]
  // );

  // useEffect(() => {
  //   if (conversation) {
  //     const handleMessageAdded = (message: Message) => setMessages(oldMessages => [...oldMessages, message]);
  //     conversation.getMessages().then(newMessages => setMessages(newMessages.items));
  //     conversation.on('messageAdded', handleMessageAdded);
  //     return () => {
  //       conversation.off('messageAdded', handleMessageAdded);
  //     };
  //   }
  // }, [conversation]);

  // useEffect(() => {
  //   // If the chat window is closed and there are new messages, set hasUnreadMessages to true
  //   if (!isChatWindowOpenRef.current && messages.length) {
  //     setHasUnreadMessages(true);
  //   }
  // }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) {
      setHasUnreadMessages(false);
    }
  }, [isChatWindowOpen]);

  const actionCompleted = (a: any) => {};

  function updateUnreadMessagesCounts() {
    chatClient?.getUnreadMessagesCounts({}).then((result: any) => {
      let unreadMessagesOnChannel: UnreadMessagesOnChannel[] = [];
      result.forEach((element: any, index: any) => {
        let newUnreadMessage: UnreadMessagesOnChannel = {
          channel: element.channel,
          count: element.count,
        };
        unreadMessagesOnChannel.push(newUnreadMessage);
      });
      setUnreadMessages(unreadMessagesOnChannel);
    });
  }

  async function fetchLatestMessages() {
    // if (!chatClient || !publicChannels) {
    //   return;
    // }

    if (!chatClient || !directChats || !Array.isArray(directChats)) {
      return;
    }

    // const latestMessages = await Promise.all(
    //   directChats?.map(async (channel) => {
    //     const messagesResponse = await channel?.getMessages({ limit: 1 });
    //     const res = {
    //       channelId: channel?.id,
    //       latestMessage: messagesResponse && messagesResponse.length ? messagesResponse.messages[0] : null,
    //     } as LatestMessagesOnChannel;

    //     return res;
    //   })
    // );

    const latestMessages = await Promise.all(
      directChats?.map(async (channel: Channel) => {
        const historicalMessagesObj = await channel?.getHistory({ count: 1 });

        const res: LatestMessagesOnChannel = {
          channelId: channel.id,
          message:
            historicalMessagesObj && historicalMessagesObj.messages.length ? historicalMessagesObj.messages[0] : null,
        };

        // //  Run through the historical messages and set the most recently received one (that we were not the sender of) as read
        // if (historicalMessagesObj.messages) {
        //   if (historicalMessagesObj.messages.length == 0) {
        //     setLoadingMessage('No messages in this chat yet')
        //   } else {
        //     setMessages(messages => {
        //       return uniqueById([...historicalMessagesObj.messages]) //  Avoid race condition where message was being added twice
        //     })
        //     for (
        //       var i = historicalMessagesObj.messages.length - 1;
        //       i >= 0;
        //       i--
        //     ) {
        //       await localCurrentMembership?.setLastReadMessageTimetoken(
        //         historicalMessagesObj.messages[i].timetoken
        //       )
        //       updateUnreadMessagesCounts()
        //       break
        //     }
        //   }
        // }
        // const messagesResponse = await channel?.getMessage({ limit: 1 });
        // const res: LatestMessagesOnChannel = {
        //   channelId: channel.id,
        //   latestMessage: messagesResponse && messagesResponse.messages.length ? messagesResponse.messages[0] : null,
        // };

        return res;
      })
    );

    // Update the state with the latest messages
    setLatestMessages(latestMessages);
  }

  /* Initialization logic */
  useEffect(() => {
    async function init() {
      // const searchParams = new URLSearchParams(window.location.search);

      // setUserId(searchParams.get('userId'));
      if (user?.id == null || user.id === '') {
        setLoadMessage('Retrieving User ID');
        return;
      }
      if (!process.env.REACT_APP_PUBNUB_PUBLISH_KEY) {
        setLoadMessage('No Publish Key Found');
        return;
      }
      if (!process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY) {
        setLoadMessage('No Subscribe Key Found');
        return;
      }
      // //  NEXT_PUBLIC_GUIDED_DEMO can be ignored and omitted from your .env file
      // setGuidedDemo(
      //   process.env.NEXT_PUBLIC_GUIDED_DEMO
      //     ? process.env.NEXT_PUBLIC_GUIDED_DEMO
      //     : null
      // )
      //const { accessManagerToken } = await getAuthKey(userId)
      const localChat = await Chat.init({
        publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
        userId: `partner_${user.id}`,
        typingTimeout: 5000,
        storeUserActivityTimestamps: true,
        storeUserActivityInterval: 300000 /* 5 minutes */,
        //authKey: accessManagerToken,
        pushNotifications: {
          sendPushes: true,
          // @ts-ignore
          deviceToken: urlB64ToUint8Array(process.env.REACT_APP_PUSH_SERVER_PUBLIC_KEY),
          deviceGateway: isIosDevice ? "apns2" : "gcm",
          apnsTopic: process.env.REACT_APP_APP_ID, //"com.trubeapp.trubeUS.dev", //| "com.trubeapp.trubeUS",
          apnsEnvironment: "development", // | "production",
        },
        // rateLimitFactor?:
      });
      setChat(localChat);
      setCurrentUser(localChat.currentUser);

      if (true || !localChat.currentUser.profileUrl) {
        // const randomProfileUrl = Math.floor(
        //   Math.random() * testData.avatars.length
        // )

        await localChat.currentUser.update({
          // @ts-ignore
          name: `${user.firstName} ${user.familyName}`, //'' + user.id,
          // @ts-ignore
          profileUrl: user!.photoWithBackgroundUrl, //testData.avatars[randomProfileUrl]
        });
        // @ts-ignore
        await localChat.currentUser.update({ name: `${user.firstName} ${user.familyName}` });
        // @ts-ignore
        setName(`${user.firstName} ${user.familyName}`); //('' + user.id);
        // @ts-ignore
        setProfileUrl(user!.photoWithBackgroundUrl /*testData.avatars[randomProfileUrl]*/);
      } else {
        if (localChat?.currentUser.name!) {
          setName(localChat?.currentUser.name!);
        }
        // @ts-ignore
        setProfileUrl(localChat.currentUser.profileUrl);
      }

      await localChat.getChannels({ filter: `type == 'direct'` }).then(async channelsResponse => {
        if (channelsResponse.channels.length < 2) {
          //  There are fewer than the expected number of public channels on this keyset, do any required Keyset initialization
          await keysetInit(localChat);
          //location.reload()
        } else {
          //  Join public channels
          if (channelsResponse.channels.length > 0) {
            setLoadMessage('Creating Memberships');
            //  Join each of the public channels
            const currentMemberships = await localChat.currentUser.getMemberships({
              filter: "channel.type == 'direct'",
            });

            //  Test to see if we are already a member of the public channels, and if not, join them.
            const publicMembership = await currentMemberships.memberships.find(
              membership => membership.channel.id == 'public-general'
            );
            const workMembership = await currentMemberships.memberships.find(
              membership => membership.channel.id == 'public-work'
            );
            if (!publicMembership) {
              const publicChannel = await localChat.getChannel('public-general');
              publicChannel?.join(message => {
                //  We have a message listener elsewhere for consistency with private and direct chats
              });
            }
            if (!workMembership) {
              const workChannel = await localChat.getChannel('public-work');
              workChannel?.join(message => {
                //  We have a message listener elsewhere for consistency with private and direct chats
              });
            }
          }
        }
      });

      //  Initialization for private groups and direct messages
      //  Calling inside a timeout as there was some timing issue when creating a new user
      let setTimeoutIdInit = setTimeout(() => {
        updateChannelMembershipsForPublic(localChat);
        updateChannelMembershipsForDirects(localChat);
        updateChannelMembershipsForGroups(localChat);
      }, 500);

      actionCompleted({
        action: 'Login',
        blockDuplicateCalls: false,
        debug: false,
      });
    }
    if (chatClient) {
      return;
    }
    init();
  }, [user /*, chatClient*/]);

  useEffect(() => {
    //  Connect to the direct chats whenever they change so we can keep a track of unread messages
    //  Called once everything is initialized
    if (!chatClient) return;
    if (!publicChannels) return;
    if (!directChats) return;
    if (!privateGroups) return;
    // if (!activeChannel) return;

    function updateUnreadMessagesCounts() {
      chatClient?.getUnreadMessagesCounts({}).then((result: any) => {
        let unreadMessagesOnChannel: UnreadMessagesOnChannel[] = [];
        result.forEach((element: any, index: any) => {
          let newUnreadMessage: UnreadMessagesOnChannel = {
            channel: element.channel,
            count: element.count,
          };
          unreadMessagesOnChannel.push(newUnreadMessage);
        });
        setUnreadMessages(unreadMessagesOnChannel);
      });
    }

    var publicHandlers: (() => void)[] = [];
    publicChannels.forEach((channel: any, index: any) => {
      const disconnectHandler = channel.connect((message: any) => {
        if (!(message.userId == chatClient.currentUser.id || message.channelId == activeChannel.id)) {
          updateUnreadMessagesCounts();
        }
      });
      publicHandlers.push(disconnectHandler);
    });
    var directHandlers: (() => void)[] = [];
    directChats.forEach((channel: any, index: any) => {
      const disconnectHandler = channel.connect((message: any) => {
        if (!(message.userId == chatClient.currentUser.id || message.channelId == activeChannel.id)) {
          updateUnreadMessagesCounts();
        }
        fetchLatestMessages();
      });
      directHandlers.push(disconnectHandler);
    });
    var privateHandlers: (() => void)[] = [];
    privateGroups.forEach((channel: any, index: any) => {
      const disconnectHandler = channel.connect((message: any) => {
        if (!(message.userId == chatClient.currentUser.id || message.channelId == activeChannel.id)) {
          updateUnreadMessagesCounts();
        }
      });
      privateHandlers.push(disconnectHandler);
    });

    updateUnreadMessagesCounts(); //  Update the unread message counts whenever the channel changes

    fetchLatestMessages();

    return () => {
      publicHandlers.forEach(handler => {
        handler();
      });
      directHandlers.forEach(handler => {
        handler();
      });
      privateHandlers.forEach(handler => {
        handler();
      });
    };
  }, [chatClient, publicChannels, directChats, activeChannel, privateGroups]);

  //  Invoked whenever the active channel changes
  useEffect(() => {
    if (!chatClient) return;
    if (!activeChannel) return;

    //  Set the pinned message for the active channel, this returns an updated channel ID so retrieve based on the server-channel
    chatClient.getChannel(activeChannel.id).then((localActiveChannel: any) => {
      localActiveChannel?.getPinnedMessage().then((localActiveChannelPinnedMessage: any) => {
        setActiveChannelPinnedMessage(localActiveChannelPinnedMessage);
      });
    });

    //  Only register typing indicators for non-public channels
    if (activeChannel.type == 'public') {
      return;
    }
    return activeChannel.getTyping((value: any) => {
      const findMe = value.indexOf(chatClient.currentUser.id);
      if (findMe > -1) {
        value.splice(findMe, 1);
      }
      setTypingData(value);
    });
  }, [chatClient, activeChannel]);

  useEffect(() => {
    //  This use effect is only called once after the local user cache has been initialized
    if (chatClient && publicChannelsUsers?.length > 0 && initOnce == 0) {
      setInitOnce(1);
      if (publicChannels) {
        // setActiveChannel(publicChannels[0]);
        // sendChatEvent(ChatEventTypes.JOINED, publicChannelsUsers[0], {
        //   userId: chatClient.currentUser.id,
        // });
        updateUnreadMessagesCounts(); //  Update the unread message counts whenever the channel changes
      } else {
        console.log('Error: Public Channels was undefined at launch');
      }
    }
  }, [chatClient, publicChannelsUsers, initOnce]);

  useEffect(() => {
    //  Get updates on the current user's name and profile URL
    if (!currentUser) {
      return;
    }
    return currentUser.streamUpdates((updatedUser: any) => {
      if (updatedUser.name) {
        setName(updatedUser.name);
      }
      if (updatedUser.profileUrl) {
        setProfileUrl(updatedUser.profileUrl);
      }
    });
  }, [currentUser]);

  /* Handle updates to the Public Channels */
  useEffect(() => {
    if (chatClient && publicChannels && publicChannels.length > 0) {
      return Channel.streamUpdatesOn(publicChannels, (channels: any) => {
        const updatedPublicChannels = publicChannels.map((publicChannel: any, index: any) => {
          if (channels[index].name) {
            (publicChannel as any).name = channels[index].name;
          }
          if (channels[index].custom?.profileUrl) {
            publicChannel.custom.profileUrl = channels[index].custom.profileUrl;
          }
          return publicChannel;
        });
        setPublicChannels(updatedPublicChannels);
      });
    }
  }, [chatClient, publicChannels]);

  /* Handle updates to the Private Groups */
  useEffect(() => {
    if (chatClient && privateGroups && privateGroups.length > 0) {
      return Channel.streamUpdatesOn(privateGroups, (channels: any) => {
        const updatedPrivateGroups = privateGroups.map((privateGroup: any, index: any) => {
          if (channels[index].name) {
            (privateGroup as any).name = channels[index].name;
          }
          return privateGroup;
        });
        setPrivateGroups(updatedPrivateGroups);
      });
    }
  }, [chatClient, privateGroups]);

  /* Handle updates to the Direct Message Groups */
  useEffect(() => {
    if (chatClient && directChats && directChats.length > 0) {
      //  Note: We do not need to stream updates on direct chats since we do not use the channel name, only the user info (name, avatar)
    }
  }, [chatClient, directChats]);

  /* Listen for events using the Chat event mechanism*/
  useEffect(() => {
    if (!chatClient) {
      return;
    }
    const removeCustomListener = chatClient.listenForEvents({
      channel: chatClient.currentUser.id,
      type: 'custom',
      method: 'publish',
      callback: async (evt: any) => {
        switch (evt.payload.action) {
          case ChatEventTypes.LEAVE:
            //  Someone is telling us they are leaving a group
            if (evt.payload.body.isDirectChat) {
              //  Our partner left the direct chat, leave it ourselves
              const channel = await chatClient.getChannel(evt.payload.body.channelId);
              await channel?.leave();
              if (activeChannel?.id === evt.payload.body.channelId) {
                if (publicChannels) {
                  setActiveChannel(publicChannels[0]);
                }
              }
            }
            refreshMembersFromServer();
            break;
          case ChatEventTypes.JOINED:
            //  Someone has joined one of the public channels
            refreshMembersFromServer();
            break;
        }
      },
    });

    const removeModerationListener = chatClient.listenForEvents({
      channel: chatClient.currentUser.id,
      type: 'moderation',
      callback: async (evt: any) => {
        // let moderationMessage = ''
        // let notificationSeverity: ToastType = ToastType.INFO
        // if (evt.payload.restriction == 'muted') {
        //   moderationMessage = `You have been MUTED by the administrator`
        //   notificationSeverity = ToastType.ERROR
        // } else if (evt.payload.restriction == 'banned') {
        //   moderationMessage = `You have been BANNED by the administrator for the following reason: ${evt.payload.reason}`
        //   notificationSeverity = ToastType.ERROR
        // } else if (evt.payload.restriction == 'lifted') {
        //   moderationMessage = `Your previous restrictions have been LIFTED by the administrator`
        //   notificationSeverity = ToastType.CHECK
        // }
        // showUserMessage(
        //   'Moderation Event:',
        //   moderationMessage,
        //   'https://www.pubnub.com/how-to/monitor-and-moderate-conversations-with-bizops-workspace/',
        //   notificationSeverity
        // );
      },
    });

    const removeMentionsListener = chatClient.listenForEvents({
      user: chatClient.currentUser.id,
      type: 'mention',
      callback: async (evt: any) => {
        const channelId = evt.payload.channel;
        const messageTimetoken = evt.payload.messageTimetoken;
        const channel = await chatClient.getChannel(channelId);
        const message = await channel?.getMessage(messageTimetoken);
        // showUserMessage(
        //   'You Were Mentioned:',
        //   'You have been mentioned in the following message: ' +
        //     message?.content.text,
        //   'https://www.pubnub.com/docs/chat/chat-sdk/build/features/custom-events#events-for-mentions',
        //   0//ToastType.INFO
        // );
      },
    });

    const removeInvite = chatClient.listenForEvents({
      channel: chatClient.currentUser.id,
      type: 'invite',
      callback: async (evt: any) => {
        //  Somebody has added us to a new group chat or DM
        refreshMembersFromServer();
      },
    });

    return () => {
      removeCustomListener();
      removeModerationListener();
      removeMentionsListener();
      removeInvite();
    };
  }, [chatClient]);

  /*
  Will refresh all of the users and channels associated with this user's memberships
  You could do this using the objects from the StreamUpdatesOn() callbacks, but
  this way is expedient for a proof of concept.  The Channel name updates use the StreamUpdatesOn()
  callback directly.
  */
  const refreshMembersFromServer = useCallback(
    async (forceUpdateDirectChannels = false, forceUpdateGroupChannels = false, desiredChannelId = '') => {
      if (!chatClient) {
        return;
      }
      //return //  TODO REMOVE THIS TO ENABLE OBJECT UPDATES.  IT'S JUST A PAIN WHEN DEBUGGING

      // @ts-ignore
      clearTimeout(refreshMembersTimeoutId);

      if (forceUpdateDirectChannels) {
        //updateChannelMembershipsForPublic(chat)  //  Not needed as we only call this when we create a new group or DM
        updateChannelMembershipsForDirects(chatClient, desiredChannelId);
      } else if (forceUpdateGroupChannels) {
        updateChannelMembershipsForGroups(chatClient, desiredChannelId);
      } else {
        let setTimeoutId: ReturnType<typeof setTimeout> = setTimeout(() => {
          updateChannelMembershipsForPublic(chatClient);
          updateChannelMembershipsForDirects(chatClient);
          updateChannelMembershipsForGroups(chatClient);
        }, 3000);
        setRefreshMembersTimeoutId(setTimeoutId);
      }

      return;
    },
    [chatClient, refreshMembersTimeoutId]
  );

  function sendChatEvent(eventType: ChatEventTypes, recipients: User[], payload: any) {
    recipients.forEach(async recipient => {
      //  Don't send the message to myself
      if (recipient.id !== chatClient?.currentUser.id) {
        await chatClient?.emitEvent({
          channel: recipient.id,
          type: 'custom',
          method: 'publish',
          payload: {
            action: eventType,
            body: payload,
          },
        });
      }
    });
  }

  // useEffect(() => {
  //   // const chat =
  //   Chat.init({
  //     publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  //     subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  //     userId: 'partner_55'
  //   }).then((chat) => {
  //     setChatClient(chat);

  //     return chat.getChannels({});
  //   }).then((res) => {
  //     //@ts-ignore
  //     setChannels(res);
  //   //   channel.join(
  //   //     callback: (message: Message) => void,
  //   //     {
  //   //         custom?: ObjectCustom
  //   //     }
  //   // )
  //   // //   : Promise<{
  //   // //     membership: Membership;
  //   // //     disconnect: () => void;
  //   // // }>
  //   //   chat.join
  //   //   activeChannel, setChannel

  //   });
  // }, []);

  //   useEffect(() => {
  //     console.log(channels);

  //     // @ts-ignore
  //     if (channels && channels.channels && channels.channels.length) {
  //       // @ts-ignore
  //       channels.channels[0].join((message: Message) => {
  //         console.log(message);
  //       }).then(() => {
  //         // @ts-ignore
  //         const channel = channels.channels[0];
  //         // @ts-ignore
  //         setChannel(channel);

  //         channel.getHistory({})
  //           .then(( res: any ) => {
  //             setMessages(res.messages);
  //           });
  //       });

  //     // : Promise<{
  //     //       messages: Message[],
  //     //       isMore: boolean
  //     //   }>
  //     }

  // //     channel.join(
  // //     callback: (message: Message) => void,
  // //     {
  // //         custom?: ObjectCustom
  // //     }
  // // ): Promise<{
  // //     membership: Membership;
  // //     disconnect: () => void;
  // // }>
  //     //channels[]
  // //     channel.getHistory({
  // //     startTimetoken?: string,
  // //     endTimetoken?: string,
  // //     count?: number
  // // }): Promise<{
  // //     messages: Message[],
  // //     isMore: boolean
  // // }>
  //   }, [channels]);

  // : Promise<{
  //       channels: Channel[],
  //       page: {
  //           next: string,
  //           prev: string,
  //       },
  //       total: number,
  //   }>

  // useEffect(() => {
  //   if (room && chatClient) {
  //     chatClient
  //       .getConversationByUniqueName(room.sid)
  //       .then(newConversation => {
  //         //@ts-ignore
  //         window.chatConversation = newConversation;
  //         setConversation(newConversation);
  //       })
  //       .catch(e => {
  //         console.error(e);
  //         onError(new Error('There was a problem getting the Conversation associated with this room.'));
  //       });
  //   }
  // }, [room, chatClient, onError]);

  return (
    <ChatContext.Provider
      value={{
        isChatWindowOpen,
        setIsChatWindowOpen,
        //connect,
        chatClient,
        currentUser,
        hasUnreadMessages,
        messages,
        conversation,
        // channel:
        activeChannel,
        setActiveChannel,
        activeChannelPinnedMessage,
        setActiveChannelPinnedMessage,
        setUnreadMessages,
        privateGroups,
        privateGroupsUsers,
        directChats,
        directChatsUsers,
        publicChannels,
        publicChannelsUsers,
        unreadMessages,
        latestMessages,
        publicChannelsMemberships,
        privateGroupsMemberships,
        directChatsMemberships,
        updateChannelMembershipsForPublic,
        updateChannelMembershipsForDirects,
        updateChannelMembershipsForGroups,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

function urlB64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
}
